import React from 'react'
import { Icon } from '../../utils'
import './styles.scss'
import { Header, Footer } from '..'

export default function Page404() {
    return (
        <>
            <Header />
            <main className='container-404'>
                <Icon name={'404'} />
            </main>
            <Footer />  
        </>
    )
}