import React from 'react'
import './style.scss'

import Logo from './logo'

export default function Header() {
    return (
        <header className='header'>
            <h1 className='header-title'>
                {/* <span>Nor</span>onha */}
                {/* <Logo /> */}
                    <Logo />
            </h1>
        </header>
    )
}
