export default {
    //APP
    SET_ISLOADING: 'SET_IS_LOADING',
    //CAMPAIGN
    ASYNC_FETCH_CAMPAIGN_INFO: 'ASYNC_FETCH_CAMPAIGN_INFO',
    ASYNC_FETCH_CAMPAIGN_INFO_ERROR: 'ASYNC_FETCH_CAMPAIGN_INFO_ERROR',
    ASYNC_FETCH_CAMPAIGN_INFO_SUCCESS: 'ASYNC_FETCH_CAMPAIGN_INFO_SUCCESS',
    ASYNC_FETCH_CAMPAIGN_VIDEOS: 'ASYNC_FETCH_CAMPAIGN_VIDEOS',
    ASYNC_FETCH_CAMPAIGN_VIDEOS_SUCCESS: 'ASYNC_FETCH_CAMPAIGN_VIDEOS_SUCCESS',
    ASYNC_FETCH_CAMPAIGN_VIDEOS_ERROR: 'ASYNC_FETCH_CAMPAIGN_VIDEOS_ERROR',
}