import { all, put ,takeLatest } from 'redux-saga/effects'
import axios from 'axios'

import { TYPES } from '../actions'
import { CONSTANTS } from '../../utils'

export default function *campaign() {
    yield all([
        yield takeLatest(TYPES.ASYNC_FETCH_CAMPAIGN_INFO, fetchCampaignInfo),
    ])
}

function *fetchCampaignInfo({ payload }) {
    
    const { userId, campaignId } = payload

    try {
        
        const { data } = yield axios({
            url: CONSTANTS.URLS.GET_CAMPAIGN(campaignId, userId),
            method: 'GET',
            data: {
                campaignId,
            }
        })

        yield all([
            put({ type: TYPES.ASYNC_FETCH_CAMPAIGN_INFO_SUCCESS, payload: { 
                ...data,
                userId: userId ? userId : 'anonym',
                campaignId 
            }}),
        ])

    } catch(error) {
        if(error && error.response && error.response.status === 404 && userId) {
            
            yield put({ type: TYPES.ASYNC_FETCH_CAMPAIGN_INFO, payload: { campaignId } })

        } else {
            yield put({ type: TYPES.ASYNC_FETCH_CAMPAIGN_INFO_ERROR, payload: 'Erro ao carregar campanha' })

            window.location.replace(`${window.location.origin}/404`)

        }
    }
}

