import React from 'react'
import Loader from 'react-loader-spinner'

import './styles.scss'

export default function Loading() {
    return (
        <>
            <Loader
                className='loader'
                type='TailSpin'
                color='#324D40'
                height={80}
                width={80}
            />
        </>
    )
}
