import { all } from 'redux-saga/effects'

import campaign from './campaign'

function *rootSaga() {
    return yield all([
        campaign(),
    ])
}

export default rootSaga