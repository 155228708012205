import { TYPES } from '../actions'

const INITIAL_STATE = {
    isLoading: false,
    campaign: {
        contact: {
            email: null,
            phone_number: null,
            social_medias: {
                facebook: null,
                instagram: null,
                linkedin: null,
                twitter: null,
            },
            text: null,
            videos: []
        }
    },
    error: null,
}
const campaign = (state = INITIAL_STATE, action) => {

    const { type, payload } = action

    switch(type) {
        case TYPES.ASYNC_FETCH_CAMPAIGN_INFO:
            return { ...state, isLoading: true, error: null }
        
        case TYPES.ASYNC_FETCH_CAMPAIGN_INFO_ERROR:
            return { ...state, isLoading: false, error: payload }
        
        case TYPES.ASYNC_FETCH_CAMPAIGN_INFO_SUCCESS:
            return { ...state, isLoading: false, error: null, campaign: payload  }
        
        default:
            return state
    }
}

export default campaign