import React from 'react'

export default ({name, classDiv, height, width, onClick = () => {}, color = '#fff'}) => (
        <div className={classDiv}>
            {/* 0 - 9 */}
            {name === '404' && 
                <svg width="1179" height="712" viewBox="0 0 1179 712">
                    <defs>
                        <path id="a" d="M151.604.056c-33.886.053-67.208 10.725-97.975 38.08-12.184 10.826-22.058 22.8-29.846 35.704-87.788 145.238 88.637 406.646 216.602 446.804 134.243 42.132 171.633 128.224 369.908 136.008 6.39.263 12.95.437 19.682.518 1.158.011 2.317.02 3.474.034.553 0 1.091.008 1.64 0 1.204.008 2.41.008 3.616 0a359.58 359.58 0 0 0 9.806-.172c3.036-.08 6.053-.209 9.048-.367 4.266-.22 8.487-.518 12.652-.885 235.251-20.538 312.444-257.942 289.938-429.666-10.195-78.007-40.98-142.459-86.859-166.878-88.872-47.306-199.975-11.624-290.094 24.056-63.997 25.34-117.414 50.679-144.775 46.295C387.634 121.447 266.696.265 152.14.057h-.536z"/>
                        <path id="c" d="M4.125 5.043C-10.333 24.005 22.627 82.666 60.788 44.74 76.046 29.58 38.246.068 15.996.072c-5 0-9.22 1.492-11.871 4.97"/>
                        <path id="e" d="M.807 4.949c-.545 1.506-.509 3.249 0 5.037C2.536 16.16 9.731 22.962 17.2 23h.137c3.354-.021 6.758-1.413 9.739-4.845 1.052-1.216 2.06-2.688 2.988-4.443C33.79 6.659 20.399.525 10.42.525 5.82.525 1.945 1.828.807 4.95"/>
                        <path id="g" d="M35.77.752S5.36 4.22 1.072 23.108c-4.288 18.89 14.037 42.405 60.041 41.248 43.712-1.1 24.07-64.15-18.465-64.142-2.23 0-4.528.172-6.878.538"/>
                        <path id="i" d="M.706.054H236.61V79.05H.706z"/>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(78)">
                            <mask id="b" fill="#fff">
                                <use xlinkHref="#a"/>
                            </mask>
                            <path fill="#DEE0E7" d="M151.604.056c-33.886.053-67.208 10.725-97.975 38.08-12.184 10.826-22.058 22.8-29.846 35.704-87.788 145.238 88.637 406.646 216.602 446.804 134.243 42.132 171.633 128.224 369.908 136.008 6.39.263 12.95.437 19.682.518 1.158.011 2.317.02 3.474.034.553 0 1.091.008 1.64 0 1.204.008 2.41.008 3.616 0a359.58 359.58 0 0 0 9.806-.172c3.036-.08 6.053-.209 9.048-.367 4.266-.22 8.487-.518 12.652-.885 235.251-20.538 312.444-257.942 289.938-429.666-10.195-78.007-40.98-142.459-86.859-166.878-88.872-47.306-199.975-11.624-290.094 24.056-63.997 25.34-117.414 50.679-144.775 46.295C387.634 121.447 266.696.265 152.14.057h-.536z" mask="url(#b)"/>
                        </g>
                        <g transform="translate(452 628)">
                            <mask id="d" fill="#fff">
                                <use xlinkHref="#c"/>
                            </mask>
                            <path d="M4.125 5.043C-10.333 24.005 22.627 82.666 60.788 44.74 76.046 29.58 38.246.068 15.996.072c-5 0-9.22 1.492-11.871 4.97" mask="url(#d)"/>
                        </g>
                        <g transform="translate(489 699)">
                            <mask id="f" fill="#fff">
                                <use xlinkHref="#e"/>
                            </mask>
                            <path d="M.807 4.949c-.545 1.506-.509 3.249 0 5.037C2.536 16.16 9.731 22.962 17.2 23h.137c3.354-.021 6.758-1.413 9.739-4.845 1.052-1.216 2.06-2.688 2.988-4.443C33.79 6.659 20.399.525 10.42.525 5.82.525 1.945 1.828.807 4.95" mask="url(#f)"/>
                        </g>
                        <g transform="translate(474 20)">
                            <mask id="h" fill="#fff">
                                <use xlinkHref="#g"/>
                            </mask>
                            <path d="M35.77.752S5.36 4.22 1.072 23.108c-4.288 18.89 14.037 42.405 60.041 41.248 43.712-1.1 24.07-64.15-18.465-64.142-2.23 0-4.528.172-6.878.538" mask="url(#h)"/>
                        </g>
                        <path fill="#D2D5DF" d="M736.204 657.444c-2.995.16-6.014.286-9.054.366-3.25.104-6.521.16-9.814.172-1.206.007-2.411.007-3.616 0-.551.007-1.09 0-1.641 0l-3.479-.034a676.72 676.72 0 0 1-19.696-.515c-198.415-7.771-235.832-93.703-370.167-135.756C190.68 481.592 14.13 220.669 101.979 75.7c-7.128 43.579-17.78 169.56 71.094 274.812 109.405 129.58 368.456 212.473 411.831 213.748 43.366 1.259-43.98-65.226 146.803-116.839 190.782-51.6 98.18 48.095 121.629 86.023 23.436 37.93 123.694-74.964 140.384-205.186 6.165-48.07 22.668-82.092 45.29-100.564 22.52 171.405-54.727 408.367-290.145 428.867-4.168.366-8.39.665-12.661.883z"/>
                        <path fill="#4D587F" d="M271.3 568.062c-2.538 16.26-8.632 42.342-14.002 62.165-3.353 12.365-6.417 22.29-8.185 25.898-.425.859-.768 1.364-1.022 1.457-2.662.928-3.328.321-4.051-2.844h-.011c-.196-.823-.39-1.832-.631-3.035-.184-.964-.414-2.064-.68-3.289-1.72-7.976-3.234-54.327-3.431-60.87-.023-.504-.032-.77-.032-.77s-12.146-7.472-20.984-14.378c-3.28-2.57-6.104-5.067-7.69-7.072-.87-1.1-1.596-2.682-2.192-4.595-3.398-11.034-2.124-33.002 4.534-34.996 0 0 41.551 6.256 58.996 27.307 1.033 1.237.643 6.91-.62 15.022"/>
                        <path fill="#364166" d="M271.3 568.062c-2.538 16.26-8.632 42.342-14.002 62.165-.14-9.338-.92-25.198-4.524-32.14-2.49-4.802-8.182-8.24-13.487-10.544-.023-.503-.032-.768-.032-.768s-12.146-7.473-20.984-14.38l-9.882-11.666c-3.398-11.034-2.124-33.002 4.534-34.996 0 0 41.551 6.256 58.996 27.307 1.033 1.237.643 6.91-.62 15.022"/>
                        <path fill="#4D587F" d="M282.353 615.892c-.725 2.097-1.528 3.186-2.423 2.877-.815-.285-1.745-1.35-2.72-3.012h-.011c-6.566-11.002-16.023-47.706-16.023-47.706s-28.327 14.633-47.473 11.505c-13.934-2.268-19.89-16.925-22.117-24.75a9.644 9.644 0 0 0 1.218-.115c4.337-.69 14.668-12.457 20.935-21.051 40.767-1.592 69.945 6.83 72.253 13.933 2.007 6.187.711 55.703-3.639 68.319"/>
                        <path fill="#272E4A" d="M173.558 450.895s-.841-11.624-9.303-12.04c-10.389-5.355-22.116 1.504-25.132 12.461-3.017 10.957 14.996 32.368 16.253 42.489 1.256 10.122-3.603 22.584-7.373 32.787-3.769 10.205 3.35 23.419 18.18 23.419 14.828 0 18.849-23 15.833-32.201-3.016-9.2-11.06-36.467-11.06-36.467l2.602-30.448z"/>
                        <path fill="#FF9696" d="M183.864 452.585l13.041-21.065s2.442-32.946 3.517-39.401c1.216-7.318-.734-14.434 2.343-18.236 0 0 4.104 42.91 4.396 44.714.292 1.804 1.611 8.388 0 15.118-1.612 6.728-13.188 35.401-13.188 35.401s-7.765-13.604-10.11-16.53"/>
                        <path fill="#6A759B" d="M218.88 525c0 1.054-2.099 4.48-5.14 8.641-6.27 8.593-16.598 20.361-20.937 21.049-.424.07-.838.103-1.216.115-5.213.103-6.416-5.259-7.6-12.515-.047-.252-.079-.502-.126-.767-1.32-8.192-13.474-31.442-15.53-33.93-.308-.366-.7-.631-1.17-.847h-.01c-2.664-1.215-7.541-.677-9.664-7.644-1.95-6.43 1.238-15.572 4.188-22.024a92.423 92.423 0 0 1 2.263-4.595c1.227-2.35 4.659-6.187 8.32-9.981 5.532-5.695 11.603-11.23 11.603-11.23s9.286 12.868 12.04 22.962c2.767 10.096 7.152 26.334 11.26 32.912 4.097 6.589 11.719 14.633 11.719 17.854"/>
                        <path fill="#596692" d="M172.946 476.287c-4.992 2.509-9.113 1.639-11.27.791a90.145 90.145 0 0 1 2.261-4.595c1.227-2.35 4.659-6.187 8.322-9.981.252.056.503.137.757.23 8.574 3.115 7.334 9.82-.07 13.555"/>
                        <path fill="#FFAFAF" d="M218.297 429.326c-1.465 2.193-5.568 32.913-8.206 36.132-2.637 3.218-13.92 20.042-18.462 24.577-4.688-4.095-7.178-17.702-6.152-19.455 1.024-1.758 12.746-10.094 12.746-10.094s10.842-32.038 14.212-35.842c3.371-3.802 4.542-5.412 5.862-5.85 1.318-.438 15.238 0 15.238 0s-13.772 8.339-15.238 10.532z"/>
                        <path fill="#FF9696" d="M161.953 466.376s3.338 5.439 4.746 5.965c1.406.526 7.292-1.14 7.467-2.982.175-1.844-3.778-7.281-3.778-7.807l-8.435 4.824z"/>
                        <path fill="#FFAFAF" d="M172.981 466.077a3.23 3.23 0 0 1-1.01.699c-2.248.985-5.727 1.295-9.021 1.145-1.171-.057-2.32-.17-3.375-.343-2.698-.413-4.81-1.157-5.27-2.074-1.134-2.291 8.609-10.096 11.95-22.117 3.157 2.041 5.418 5.227 6.818 8.606 2.33 5.615 2.273 11.746-.092 14.084"/>
                        <path fill="#364166" d="M261.566 684.693c0 2.533-22.657-9.362-22.313-12.09.345-2.739 4.776-17.864 4.776-17.864h.01c.724 3.163 1.39 3.77 4.052 2.842.254-.092.597-.596 1.022-1.455 0 0 12.453 26.033 12.453 28.567M294.587 648.997c-2.34 1.365-17.973-12.672-18.948-15.021-.977-2.338 1.56-18.219 1.56-18.219h.011c.975 1.66 1.905 2.727 2.72 3.013.895.308 1.698-.78 2.423-2.878 2.867 6.349 14.29 31.914 12.234 33.105"/>
                        <path fill="#596692" d="M189.142 516.44c.482 7.023-2.743 20.214-5.154 25.85-.047-.251-.08-.503-.126-.766-1.32-8.194-13.475-31.444-15.53-33.93-.31-.367-.7-.632-1.171-.848 9.952.607 24.471-16.71 24.471-16.71 5.418 7.014-3.226 15.654-2.49 26.404"/>
                        <path fill="#F49696" d="M173.073 451.993s-2.639-2.714-5.003-4.742c-.599 6.37-10.768 15.204-8.496 20.327-2.697-.413-4.81-1.157-5.269-2.075-1.135-2.29 8.61-10.095 11.95-22.116 3.157 2.041 5.418 5.227 6.818 8.606"/>
                        <path fill="#6A759B" d="M276.862 362.651h50.03c1.357 0 2.456-1.1 2.456-2.454v-85.8c0-2.472-3.247-3.393-4.546-1.29l-.004.006-50.06 85.851c-.952 1.635.228 3.687 2.124 3.687m127.947 0h27.146a2.454 2.454 0 0 1 2.455 2.453v51.325c0 1.355-1.1 2.453-2.455 2.453h-27.146a2.456 2.456 0 0 0-2.459 2.452v61.926c0 1.355-1.1 2.45-2.456 2.45h-68.09a2.452 2.452 0 0 1-2.456-2.45v-61.925c0-1.356-1.1-2.453-2.457-2.453H209.614a2.453 2.453 0 0 1-2.443-2.216l-4.038-41.511a2.455 2.455 0 0 1 .358-1.53l125.857-202.163v-.649h70.546a2.454 2.454 0 0 1 2.456 2.453v186.93a2.457 2.457 0 0 0 2.459 2.455M612.48 290.199c0-22.633-2.962-39.542-8.884-50.717-5.922-11.173-15.307-16.762-28.16-16.762-12.566 0-21.7 5.191-27.403 15.573-5.71 10.381-8.774 26.098-9.207 47.147v80.456c0 23.501 2.957 40.695 8.881 51.583 5.92 10.887 15.306 16.326 28.163 16.326 12.419 0 21.588-5.296 27.512-15.896 5.92-10.598 8.95-27.213 9.098-49.852v-77.858zm73.002 66.828c0 42.536-9.569 75.338-28.701 98.407-19.14 23.068-46.109 34.603-80.91 34.603-35.097 0-62.249-11.605-81.455-34.819-19.208-23.214-28.81-55.943-28.81-98.191v-57.53c0-42.531 9.566-75.333 28.702-98.405 19.135-23.067 46.176-34.604 81.127-34.604 34.95 0 62.026 11.609 81.238 34.822 19.203 23.216 28.809 56.015 28.809 98.404v57.313zM790.703 362.651h50.027c1.358 0 2.457-1.1 2.457-2.454V274.4c0-2.47-3.247-3.39-4.546-1.287l-50.061 85.851c-.953 1.635.228 3.687 2.123 3.687m127.947 0h27.146a2.454 2.454 0 0 1 2.455 2.453v51.325c0 1.355-1.1 2.453-2.455 2.453H918.65a2.456 2.456 0 0 0-2.459 2.452v61.926c0 1.355-1.1 2.45-2.456 2.45h-68.091a2.452 2.452 0 0 1-2.457-2.45v-61.925c0-1.356-1.1-2.453-2.457-2.453H723.454a2.455 2.455 0 0 1-2.444-2.216l-4.038-41.511a2.455 2.455 0 0 1 .358-1.53l125.857-202.163v-.649h70.548a2.454 2.454 0 0 1 2.456 2.453v186.93a2.457 2.457 0 0 0 2.459 2.455"/>
                        <path fill="#364166" d="M896.933 666.259s-7.823 3.304-12.568 14.93c-4.742 11.625-5.461 28.13-3.306 30.283 2.157 2.152 17.97-14.927 19.408-24.973 1.438-10.047-.448-17.872-.448-17.872l-3.086-2.368z"/>
                        <path fill="#FFAFAF" d="M975.392 424.351c-5.865 4.823-8.057 13.315-8.057 13.315s-13.924-9.075-15.095-10.829c-1.169-1.753-4.843-34.08-5.864-42.124-1.022-8.055 1.905-15.367 4.396-12.73 2.49 2.634 1.606 12.283 1.468 12.868-.148.584 8.496 28.968 9.516 29.987 1.033 1.031 13.636 9.513 13.636 9.513"/>
                        <path fill="#6A759B" d="M1069.796 469.854c-.07.254-.148.47-.24.677-25.056 7.459-30.577 7.506-30.577 7.506s-1.71 3.16-4.098 7.735c-3.271 6.268-7.84 15.195-11.065 22.288-2.136 4.685-3.684 8.571-3.88 10.359-.206 1.879-2.271 3.048-5.313 3.54-7.415 1.19-20.66-1.672-27.512-8.515-6.428-6.416-8.77-11.987-9.286-16.283-.264-2.19-.047-4.044.344-5.523.37-1.376 1.55-3.047 3.02-5.432 2.696-4.377 6.323-11.148 7.47-22.757a77.67 77.67 0 0 0 .357-7.645c-21.245-14.482-21.682-18.138-21.682-18.138s2.191-8.493 8.059-13.315c0 0 26.375 18.438 28.867 19.308.732.264 3.085 1.066 6.15 2.119 4.879 1.674 11.571 3.967 16.447 5.685 2.47.872 4.455 1.593 5.534 2.005 4.533 1.753 34.433.137 35.89 0h.023c1.389 0 2.835 11.814 1.492 16.386"/>
                        <path fill="#000" d="M1026.857 451.462c-1.777 3.703-5.014 7.862-10.295 6.313-6.669-1.947-6.633-8.65-6.151-11.996 4.877 1.673 11.57 3.966 16.446 5.683"/>
                        <path fill="#FFAFAF" d="M1129.916 416.202c-4.098 9.57-20.119 19.907-20.119 19.907s-18.95 24.956-23.44 28.28c-2.594 1.913-10.421 4.022-16.56 5.465 1.34-4.572-.103-16.386-1.492-16.386.596-.08 12.051-1.593 14.139-2.543 2.159-.975 18.952-16.972 19.341-17.557.39-.584 5.279-15.012 6.256-15.595.973-.586 2.34 5.454 2.34 5.454s13.096-6.818 19.535-7.025M1030.462 433.897a6.416 6.416 0 0 1-.837 3 30.017 30.017 0 0 1-2.365 3.508l-.011.011c-1.355 1.695-2.778 3.037-4.168 4.058-4.132 3.046-8 3.367-9.126 2.6a15.587 15.587 0 0 1-.664-.494c-5.233-4.112-5.474-11.676-5.071-16.18.056-.72.137-1.35.216-1.89.173-1.11.322-1.787.322-1.787 2.765 2.979 14.071-.769 16.906-.276 2.216.377 4.89 3.862 4.798 7.45"/>
                        <path fill="#FF9696" d="M1023.085 444.479c-.51 3.578.544 7.559-2.172 9.339-3.236 2.114-6.007.366-7.298-1.205-.838-1.02-.588-4.022-.315-6.03.217.174.433.337.661.488 1.12.77 4.987.456 9.124-2.592"/>
                        <path fill="#020716" d="M1036.181 433.786c-1.55 3.047-5.67 4.804-8.915 6.616a28.333 28.333 0 0 0 2.357-3.505c2.498-4.358-1.109-9.963-3.955-10.451-2.835-.489-14.143 3.25-16.91.28 0 0-.152.674-.327 1.778-4.834-1.54-14.271-5.648-14.685-14.28-.554-11.637 7.398-15.052 16.738-12.46 9.33 2.58 13.119 12.46 13.119 12.46s9.245-5.26 15.35.27c6.104 5.543-3.139 12.743-3.139 12.743s2.856 1.657.367 6.55"/>
                        <path fill="#4D587F" d="M1014.623 521.959c-2.078 5.132-5.58 12.937-10.812 21.91-8.287 14.232-84.49 116.39-103.244 131.367-.4.321-.871.447-1.364.447-2.594-.047-6.107-3.759-6.164-4.447-.585-6.966 73.136-134.438 74.283-136.42l.013-.023-49.975 11.517s-17.25 32.956-29.085 48.528c-1.056 1.376-2.054 2.613-2.995 3.678-2.756 3.118-5.017 4.79-6.404 4.16-.24-.113-.277-.952-.137-2.395 1.32-13.349 17.883-77.909 23.965-86.883 5.14-7.574 49.295-12.135 68.693-15.102 2.664-.403 4.866-.78 6.427-1.134.518 4.297 2.86 9.867 9.288 16.283 6.85 6.841 20.097 9.705 27.511 8.514"/>
                        <path fill="#364166" d="M890.398 611.935c-3.12 4.39-34.96 21.165-32.229 11.609 2.732-9.557 20.489-23.205 20.489-23.205l.08-.058c-.139 1.443-.103 2.282.138 2.394 1.387.632 3.648-1.04 6.404-4.159 1.572 4.676 7.806 9.649 5.118 13.419M1014.623 521.959c-2.078 5.132-5.58 12.937-10.812 21.91-8.287 14.232-84.49 116.39-103.244 131.367-.4.321-.871.447-1.364.447 21.198-34.858 92.212-137.269 84.98-153.46-4.81-10.75-9.561-18.874-12.786-23.927 2.664-.403 4.866-.78 6.427-1.134.518 4.297 2.86 9.867 9.288 16.283 6.85 6.841 20.097 9.705 27.511 8.514"/>
                        <path fill="#364166" d="M967.335 534.793L917.36 546.31s-17.25 32.956-29.085 48.529c8.266-19.309 22.324-51.715 24.987-54.774 3.812-4.389 43.663-21.647 48.356-17.841 4.683 3.804 5.717 12.569 5.717 12.569"/>
                        <path fill="#475176" d="M1034.881 485.772c-3.271 6.267-7.84 15.194-11.064 22.287-12.236-.162-29.338-4.159-42.628-21.853 2.696-4.377 6.323-11.149 7.47-22.757 8.024 7.31 25.171 20.477 46.222 22.323"/>
                        <path fill="#ED9090" d="M1030.462 433.897c-.297-1.617-.918-3.165-2.134-4.08-3.41-2.59-11.81 1.706-17.482 1.02a27.153 27.153 0 0 1-2.626-.436c.056-.722.137-1.351.216-1.89.173-1.111.322-1.788.322-1.788 2.765 2.979 14.071-.769 16.906-.276 2.216.377 4.89 3.862 4.798 7.45"/>
                        <path fill="#444D6E" d="M102.238 277.065s94.505-71.412-50.525-156.618c-26.7 55.606 42.922 109.117 50.525 156.618"/>
                        <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.219" d="M51.714 120.446s6.413 28.138 33.405 58.305c26.99 30.17 41.786 49.491 17.119 98.313"/>
                        <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.219" d="M48.723 167.488l29.505 3.067 11.151-24.19M65.553 204.286l36.22-5.53 8.898-31.693M82.568 232.208l32.06-2.27 12.973-35.65"/>
                        <path fill="#6A759B" d="M136.574 350.51s67.95-126.715-135.426-147.197c-1.17 74.899 102.871 98.64 135.426 147.198"/>
                        <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.219" d="M1.148 203.314s21.391 27.794 66.523 47.396c45.129 19.603 71.285 33.42 68.902 99.801"/>
                        <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.219" d="M21.779 256.737l34.117-11.577v-32.345M59.07 288.806l37.152-24.468-6.304-39.481M92.05 310.99l34.222-18.76-3.823-45.917"/>
                        <path fill="#6A759B" d="M775.057 577.62c-1.585 12.65-4.27 24.35-7.448 34.859a225.527 225.527 0 0 1-10.847 28.602 212.184 212.184 0 0 1-7.897 15.48c-4.167.366-8.39.663-12.66.883-2.996.16-6.015.286-9.055.367-3.25.103-6.521.16-9.813.17-1.207.014-2.412.014-3.617 0-.551.014-1.09 0-1.641 0-1.16-.01-2.32-.022-3.479-.033a9.498 9.498 0 0 1-.331-1.318s.149-3.14.975-8.663c.78-5.236 2.169-12.628 4.636-21.53a225.705 225.705 0 0 1 2.434-8.104 207.453 207.453 0 0 1 2.559-7.473 211.934 211.934 0 0 1 6.394-15.617 221.44 221.44 0 0 1 17.17-30.983c8.47-12.756 19.03-25.842 32.186-38.527 2.695 18.46 2.443 35.924.434 51.887"/>
                        <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.219" d="M727.15 657.81c1.125-3.942 2.65-8.79 4.464-14.279.115-.33.217-.674.334-1.007 2.547-7.69 5.634-16.57 8.964-25.933 3.693-10.393 7.69-21.37 11.592-31.982 11.454-31.064 22.118-58.876 22.118-58.876"/>
                        <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.219" d="M742.435 564.254l10.07 20.351 22.554-6.985M724.971 594.839l15.938 21.75 26.699-4.11M713.88 626.441l17.735 17.097 25.143-2.46"/>
                        <path fill="#444D6E" d="M736.205 657.443c-2.997.16-6.014.286-9.056.367-3.248.103-6.52.16-9.812.17-1.206.014-2.413.014-3.616 0-.551.014-1.09 0-1.641 0-1.162-.01-2.32-.022-3.479-.031a676.606 676.606 0 0 1-19.696-.517 214.064 214.064 0 0 1-11.524-27.858 220.556 220.556 0 0 1-8.493-34.353c-2.502-15.104-3.684-31.868-2.675-50.11 14.555 11.698 26.273 24.68 35.675 37.744 6.805 9.444 12.394 18.92 16.985 28.007.448.872.885 1.753 1.297 2.612a221.81 221.81 0 0 1 11.536 28.328l.24.723.31.95a203.522 203.522 0 0 1 3.949 13.968"/>
                        <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.219" d="M713.72 657.981a1345.275 1345.275 0 0 1-4.476-10.015 1441.465 1441.465 0 0 1-5.841-13.36 2737.46 2737.46 0 0 1-13.28-31.32c-12.788-30.547-23.91-58.177-23.91-58.177"/>
                        <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.219" d="M668.871 595.208l21.245 8.08 11.775-20.437M676.905 629.486l26.502 5.123 16.77-21.144M731.706 641.801l-.873.985-13.497 15.195"/>
                        <path fill="#6A759B" d="M996.926 113.786s12.016-32.768 50.404-32.768c38.39 0 48.94 43.593 48.94 43.593s2.638-10.532 24.03-10.825c21.392-.291 27.84 24.577 27.84 24.577s20.22-10.241 30.478 21.65H942.712s10.551-50.615 54.214-46.227"/>
                        <g transform="translate(43.296 2)">
                            <mask id="j" fill="#fff">
                                <use xlinkHref="#i"/>
                            </mask>
                            <path fill="#6A759B" d="M182.395 32.823S170.38.054 131.991.054c-38.39 0-48.939 43.594-48.939 43.594s-2.637-10.532-24.029-10.825c-21.392-.293-27.841 24.576-27.841 24.576S10.962 47.16.706 79.05H236.61s-10.551-50.615-54.216-46.226" mask="url(#j)"/>
                        </g>
                    </g>
                </svg>
            }
            {/* A */}
            
            {/* B */}

            {/* C */}
            
            {/* D */}
            
            {/* E */}
            {name === 'email' &&
                <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                    <g fill="#FFF">
                        <path d="M15.438 7.071L6.562 7.071 11 10.876z"/>
                        <path d="M11 11.786a.392.392 0 01-.256-.095L5.893 7.533v7.396h10.214V7.533l-4.851 4.158a.392.392 0 01-.256.095z"/>
                        <path d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11C21.993 4.928 17.072.007 11 0zm5.893 15.321a.393.393 0 01-.393.393h-11a.393.393 0 01-.393-.393V6.68c0-.217.176-.393.393-.393h11c.217 0 .393.176.393.393v8.642z"/>
                    </g>
                </svg>
            }

            {/* F */}
            {name === 'facebook' && 
                <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                    <path fill="#FFF" d="M10.923 0C4.9 0 0 4.9 0 10.923s4.9 10.924 10.923 10.924 10.924-4.9 10.924-10.924C21.847 4.9 16.947 0 10.923 0zm2.717 11.308h-1.777v6.334H9.229v-6.334H7.978V9.069h1.251V7.621c0-1.037.493-2.657 2.658-2.657l1.951.007v2.173h-1.416c-.23 0-.558.116-.558.61V9.07h2.006l-.23 2.238z"/>
                </svg>
            }

            {/* G */}

            {/* H */}

            {/* I */}
            {name === 'instagram' &&
                <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                    <g fill="#FFF">
                        <path d="M10.923 13.11a2.19 2.19 0 002.188-2.187 2.18 2.18 0 00-.414-1.274 2.184 2.184 0 00-1.773-.912c-.73 0-1.375.36-1.773.911a2.177 2.177 0 00-.414 1.275 2.188 2.188 0 002.186 2.187zM15.698 8.248L15.698 6.415 15.698 6.142 15.424 6.143 13.592 6.149 13.599 8.255z"/>
                        <path d="M10.923 0C4.9 0 0 4.9 0 10.923s4.9 10.924 10.923 10.924 10.924-4.9 10.924-10.924C21.847 4.9 16.947 0 10.923 0zm6.213 9.649v5.086a2.404 2.404 0 01-2.401 2.402H7.112a2.403 2.403 0 01-2.4-2.402V7.113a2.403 2.403 0 012.4-2.401h7.622a2.404 2.404 0 012.402 2.4V9.65z"/>
                        <path d="M14.322 10.923a3.402 3.402 0 01-3.399 3.399 3.402 3.402 0 01-3.397-3.399c0-.45.09-.88.25-1.274H5.92v5.086c0 .658.534 1.19 1.19 1.19h7.623c.656 0 1.19-.532 1.19-1.19V9.65H14.07c.161.393.253.824.253 1.274z"/>
                    </g>
                </svg>
            }

            {/* J */}
            
            {/* K */}

            {/* L */}
            {name === 'linkedin' &&
                <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} >
                    <g fill="#FFF">
                        <path d="M12.914 9.371c-.935 0-1.353.514-1.586.875v-.75H9.567c.023.496 0 5.298 0 5.298h1.76v-2.96c0-.158.01-.317.058-.43.128-.316.418-.644.904-.644.637 0 .892.486.892 1.198v2.835h1.761v-3.038c0-1.627-.87-2.384-2.028-2.384zM6.83 9.496L8.591 9.496 8.591 14.794 6.83 14.794z"/>
                        <path d="M10.923 0C4.9 0 0 4.9 0 10.923s4.9 10.924 10.923 10.924 10.924-4.9 10.924-10.924C21.847 4.9 16.947 0 10.923 0zm5.793 15.864a.85.85 0 01-.862.84H5.918a.85.85 0 01-.86-.84V5.813c0-.464.385-.84.86-.84h9.936c.476 0 .862.376.862.84v10.051z"/>
                        <path d="M7.722 6.942c-.601 0-.996.395-.996.915 0 .508.383.915.973.915h.012c.614 0 .996-.407.996-.915-.012-.52-.382-.915-.985-.915z"/>
                    </g>
                </svg>
            }

            {/* M */}

            {/* N */}

            {/* O */}

            {/* P */}
            {name === 'phone' &&
                <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                    <path fill="#FFF" d="M18.74 3.214C14.452-1.072 7.501-1.07 3.214 3.217c-4.286 4.288-4.285 11.239.003 15.526 4.288 4.286 11.239 4.285 15.526-.003a10.979 10.979 0 00-.003-15.526zm-2.113 12.079l-.002.001v-.003l-.556.552a2.928 2.928 0 01-2.763.79 9.728 9.728 0 01-2.825-1.265 13.053 13.053 0 01-2.229-1.797 13.153 13.153 0 01-1.665-2.02 10.246 10.246 0 01-1.251-2.533 2.928 2.928 0 01.731-2.99l.652-.65a.465.465 0 01.657-.002l.002.001 2.056 2.057a.465.465 0 01.002.657l-.002.002L8.227 9.3a.984.984 0 00-.103 1.281c.437.6.92 1.164 1.446 1.687a14.037 14.037 0 001.903 1.6.992.992 0 001.266-.11l1.167-1.186a.465.465 0 01.659 0l2.06 2.064a.465.465 0 01.002.657z"/>
                </svg>
            }

            {/* Q */}

            {/* R */}

            {/* S */}

            {/* T */}
            {name === 'twitter' && 
                <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                    <path fill="#FFF" d="M9.93 0C4.455 0 0 4.455 0 9.93c0 5.476 4.455 9.93 9.93 9.93 5.476 0 9.93-4.454 9.93-9.93C19.86 4.455 15.407 0 9.93 0zm4.43 7.658c.005.098.007.197.007.296 0 3.02-2.298 6.502-6.503 6.502A6.465 6.465 0 014.36 13.43a4.587 4.587 0 003.384-.946c-1-.018-1.844-.68-2.135-1.588a2.304 2.304 0 001.032-.038 2.286 2.286 0 01-1.833-2.241v-.029c.308.17.66.274 1.035.286a2.284 2.284 0 01-.707-3.052 6.49 6.49 0 004.71 2.389 2.286 2.286 0 013.895-2.085 4.562 4.562 0 001.452-.555 2.296 2.296 0 01-1.006 1.265 4.544 4.544 0 001.313-.36c-.305.458-.693.86-1.14 1.182z"/>
                </svg>
            }

            {/* U */}

            {/* V */}

            {/* W */}

            {/* X */}

            {/* Y */}

            {/* Z */}
        </div>
)