import React, { 
    Suspense, lazy 
} from 'react'

import {
    Route,
    Switch,
} from 'react-router-dom'

import { CONSTANTS } from '../utils'
import { Loading, Page404 } from '../components'

const CampaignContainer = lazy(() => import('../containers/campaignContainer'))

function Routes() {
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <Route 
                    path={CONSTANTS.ROUTES.COMPLETE_CAMPAIGN}
                    exact
                    component={({ match, location, history }) => <CampaignContainer match={match} location={location} history={history} /> } />
                <Route 
                    path={CONSTANTS.ROUTES.ANONYMOUS_CAMPAIGN}
                    exact
                    component={({ match, location, history }) => <CampaignContainer match={match} location={location} history={history} /> } />
                />
                <Route path={'*'} >
                    <Page404 />
                </Route>
            </Switch>
        </Suspense>
    )
}

export default Routes

