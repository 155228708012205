import Axios from 'axios'

export default {
    api: {
        async post(request) {
            try {      
                
                const { data } = await Axios({
                    method: 'POST',
                    ...request
                })
    
                return data
                
            } catch(error) {
                throw error
            }
        },
    
        async get(url) {
            try {
                
                const { data } = await Axios.get(url)
                
                return data

            } catch(error) {
                throw error
            }
        }
    },

    maskPhone(phone) {
        let regex = phone.replace(/\D/g,"")
        regex = regex.replace(/^0/,"")
        if (regex.length > 10) {
            regex = regex.replace(/^(\d\d)(\d{5})(\d{4}).*/,"($1) $2-$3")
        }
        else if (regex.length > 5) {
            regex = regex.replace(/^(\d\d)(\d{4})(\d{0,4}).*/,"($1) $2-$3")
        }
        else if (regex.length > 2) {
            
            regex = regex.replace(/^(\d\d)(\d{0,5})/,"($1) $2")
        }
        else {
            
            regex = regex.replace(/^(\d*)/, "($1")
        }
        return regex
    }
    
}