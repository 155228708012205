import Header from './header'
import Footer from './footer'
import Loading from './Loading'
import Page404 from './page404'

export {
    Header,
    Footer,
    Loading,
    Page404,
}