import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import './store/reactotron.config'

import { history } from './utils'
import { Routes } from './Routes'

import { store } from './store'


function App() {

  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  )
}

export default App
