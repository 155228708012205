const {
    REACT_APP_URL_GET_CAMPAIGN,
    REACT_APP_URL_SEND_EVENTS,
} = process.env

export default {
    URLS: {
        GET_CAMPAIGN : (campaignId, userId) => {
            return userId 
                ? `${REACT_APP_URL_GET_CAMPAIGN}/public/campaigns/${campaignId}/${userId}` 
                : `${REACT_APP_URL_GET_CAMPAIGN}/public/campaigns/${campaignId}`
        },
        SEND_EVENTS: REACT_APP_URL_SEND_EVENTS,
    },
    ROUTES: {
        ANONYMOUS_CAMPAIGN: '/campaigns/:campaignId',
        COMPLETE_CAMPAIGN: '/campaigns/:campaignId/:userId'
    }   
}