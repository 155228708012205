import React, { useEffect, useState } from 'react'

import { Icon,  functions } from '../../utils'

import './styles.scss'

export default function Footer({
    coachInfo
}) {

    const [ facebook, setFacebook ] = useState(null)
    const [ instagram, setInstagram ] = useState(null)
    const [ linkedin, setLinkedin ] = useState(null)
    const [ twitter, setTwitter ] = useState(null)

    const [ email, setEmail ] = useState(null)
    const [ phone, setPhone ] = useState(null)

    useEffect(() => {
        
        if(coachInfo) {
            coachInfo.email && setEmail(coachInfo.email)
            coachInfo.phone && setPhone(coachInfo.phone)

            if(coachInfo.social_medias) {
                coachInfo.social_medias.facebook && setFacebook(coachInfo.social_medias.facebook)
                coachInfo.social_medias.linkedin && setLinkedin(coachInfo.social_medias.linkedin)
                coachInfo.social_medias.instagram && setInstagram(coachInfo.social_medias.instagram)
                coachInfo.social_medias.twitter && setTwitter(coachInfo.social_medias.twitter)
            }

        }

    }, [coachInfo])

    return (
        <footer className='footer'>
            <div className='footer-col'>
                {email && (
                    <a href={`mailto:${email}`}>
                        <Icon classDiv={'footer-icon'} name='email' width={22} height={22} />
                        <span>{email}</span>
                    </a>
                )}

            </div>
            <div className='footer-col'>
                {phone && (        
                    <a href={`tel:${phone}`}>
                        <Icon classDiv={'footer-icon'} name='phone' width={22} height={22} />
                        <span>{functions.maskPhone(phone)}</span>
                    </a>
                )}
            </div>
            <div className='footer-col'>
                {facebook && (
                    <a classDiv={'footer-icon'} href={facebook} target='blank'><Icon classDiv={'footer-icon'} name='facebook' width={22} height={22} /></a>
                )}
                {instagram && (
                    <a classDiv={'footer-icon'} href={instagram} target='blank'><Icon classDiv={'footer-icon'} name='instagram' width={22} height={22} /></a>
                )}
                {linkedin && (
                    <a classDiv={'footer-icon'} href={linkedin} target='blank'><Icon classDiv={'footer-icon'} name='linkedin' width={22} height={22} /></a>
                )}
                {twitter && (
                    <a classDiv={'footer-icon'} href={twitter} target='blank'><Icon classDiv={'footer-icon'} name='twitter' width={22} height={22} /></a>
                )}
                
            </div>
        </footer>
    )
}
